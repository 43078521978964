import { RefObject, useEffect, useRef, useState } from 'react'
import './ExhumanComponent.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentAvatarConfig, selectAvatarAnswer, selectIsExhumanMuted, setIsExhumanMuted, selectIsOnlyFirstIntroMessage, setAvatarAnswer } from '../../app/redux/defaultSlice'
import { customAlert } from '../../app/utils'
import { useCookies } from 'react-cookie'
import { firstInteractionPlayedKey } from '../../app/const-keys'
import { AvatarConfig } from '../../app/types'
import Hls from 'hls.js'
import { AutoTextSize } from 'auto-text-size'

export default () => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const verifiedWidth = avatarConfig?.is_verified ? 24 : 0
    const onlineBadgeWidth = 50
    const titleGap = 8
    const gaps = titleGap + (avatarConfig?.is_verified ? titleGap : 0)

    return <>
        <div className='h-full'>
            <div className='exh__column flex flex-col'>

                <div className='flex items-center' style={{ gap: titleGap }}>
                    <div className='exh__avatar-name mb-[8px]' style={{ maxWidth: `calc(100% - ${verifiedWidth + onlineBadgeWidth + gaps}px)` }}>
                        <AutoTextSize maxFontSizePx={30}>
                            {avatarConfig?.display_name}
                        </AutoTextSize>
                    </div>

                    {avatarConfig?.is_verified && <div className='pb-[6px]'>
                        <img width={verifiedWidth} height={24} src='/images/verified.svg' />
                    </div>}

                    <div className='pb-[6px] ml-[4px] w-[50px]'>
                        <img width={onlineBadgeWidth} src='/images/online.svg' />
                    </div>
                </div>

                <div className='exh__profession mb-[24px]'>
                    {avatarConfig?.profession}
                </div>

                <div className='exh__bot-head relative'>
                    <img className='exh__bot-head' />
                    <video
                        ref={videoRef}
                        className='exh__bot-head absolute top-0 left-0 bottom-0 right-0'
                        autoPlay
                        loop
                        src={avatarConfig?.exhuman_idle_url} />
                </div>

                <div className='flex flex-col flex-grow'>

                    <div className='exh__bio mb-[12px]'>
                        {avatarConfig?.bio}
                    </div>

                    <div>
                        <AudioVideoToggleComponent videoRef={videoRef} />
                    </div>

                    <div className='flex-grow min-h-[20px] max-h-full'></div>
                </div>

            </div>
        </div>
        <AnswerHandler videoRef={videoRef} />
        <IntroMessageChecker
            videoRef={videoRef}
            avatarConfig={avatarConfig}
        />
    </>
}

const AnswerHandler = ({ videoRef }: {
    videoRef: RefObject<HTMLVideoElement>
}) => {

    const dispatch = useDispatch()
    const avatarAnswer = useSelector(selectAvatarAnswer)
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const isMuted = useSelector(selectIsExhumanMuted)
    useEffect(() => {
        if (isMuted && videoRef.current) {
            if (videoRef.current.src != avatarConfig?.exhuman_idle_url) {
                videoRef.current.src = avatarConfig?.exhuman_idle_url ?? ''
            }
        }
    }, [isMuted])

    useEffect(() => {
        if (avatarAnswer) {
            if (!avatarConfig) {
                customAlert('no avatar config, please check url for a real avatar')
                return
            }

            (async () => {
                const m3u8Url = avatarAnswer.m3u8Url
                if (m3u8Url && !isMuted) {
                    const ok = await waitForFileAvailability(m3u8Url)
                    if (ok) {
                        doHLS(m3u8Url, videoRef, function onFinish() {
                            const videoElement = videoRef.current!
                            videoElement.loop = true
                            videoElement.src = avatarConfig.exhuman_idle_url
    
                            videoElement.load()
                        })
                    } else {
                        customAlert(`Stream isn't available before timeout`)
                    }
                    
                } else {
                    const videoBlob = avatarAnswer.exhumanVideoBlob
                    const videoElement = videoRef.current
                    if (videoBlob && videoElement && !isMuted) {
                        const videoUrl = URL.createObjectURL(videoBlob)
                        videoElement.src = videoUrl
                        videoElement.loop = false
                        videoElement.load()
                        videoElement.addEventListener('ended', () => {
                            videoElement.loop = true
                            videoElement.src = avatarConfig.exhuman_idle_url
    
                            videoElement.load()
                        })
                    }
                }
            })()
        }
        dispatch(setAvatarAnswer(null))

    }, [avatarAnswer])

    return <></>
}

const waitForFileAvailability = async (url: string, timeoutSec: number = 20) => {
    let attempt = 0
    const recheckInterval = 100
    const maxAttempts = timeoutSec * 1000 / recheckInterval

    return new Promise((resolve, reject) => {
        const checkFile = async () => {
            try {
                const response = await fetch(url, { method: 'HEAD' })

                if (response.ok) {
                    console.log('File is available for download.')
                    return resolve(true)
                } else {
                    console.log(`File is not available. Status: ${response.status}`)
                }
            } catch (error) {
                console.error('Error checking file availability:', error)
            }

            attempt++
            if (attempt < maxAttempts) {
                setTimeout(checkFile, recheckInterval)
            } else {
                console.log('Max attempts reached. File not available.')
                return resolve(false)
            }
        };

        checkFile()
    });
}

const doHLS = (url: string, videoRef: RefObject<HTMLVideoElement>, onFinish: () => void) => {
    const videoElement = videoRef.current!
    videoElement.loop = false
    const videoSrc = url

    if (Hls.isSupported()) {
        const config = {playlistLoadPolicy: {
            default: {
              maxTimeToFirstByteMs: 10000,
              maxLoadTimeMs: 20000,
              timeoutRetry: {
                maxNumRetry: 20,  // When GAE is slow it might take more time.
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
              },
              errorRetry: {
                maxNumRetry: 100,  // If the playlist doesn't have sgements yet, keep trying.
                retryDelayMs: 100,
                maxRetryDelayMs: 500,
              },
            },
          },};
        const hls = new Hls(config)
        hls.loadSource(videoSrc)
        hls.attachMedia(videoElement)

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoElement.play()
        })

        hls.on(Hls.Events.ERROR, function (event, data) {
            console.error('HLS error:', data)
        })

        videoElement.addEventListener('ended', () => {
            onFinish()
        })

    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        videoElement.src = videoSrc;
        videoElement.addEventListener('canplay', () => {
            videoElement.play()
        })

        videoElement.addEventListener('ended', () => {
            onFinish()
        })
    } else {
        console.error('HLS is not supported in this browser.')
    }
}

const IntroMessageChecker = ({ videoRef, avatarConfig }: {
    videoRef: RefObject<HTMLVideoElement>,
    avatarConfig: AvatarConfig | null
}) => {
    const [cookies, setCookie] = useCookies([firstInteractionPlayedKey])
    const isFirstInteractionPlayed = cookies[firstInteractionPlayedKey] ?? {}

    const isOnlyFirstIntroMessage = useSelector(selectIsOnlyFirstIntroMessage)
    useEffect(() => {
        const videoElement = videoRef.current
        if (!isFirstInteractionPlayed[avatarConfig?.name ?? ''] &&
            isOnlyFirstIntroMessage &&
            videoElement &&
            avatarConfig?.first_chat_message_video_url) {

            videoElement.src = avatarConfig?.first_chat_message_video_url
            videoElement.loop = false
            videoElement.load()
            videoElement.addEventListener('ended', () => {
                const isFirstInteractionPlayedUpd = { ...isFirstInteractionPlayed }
                isFirstInteractionPlayedUpd[avatarConfig.name] = true
                setCookie(firstInteractionPlayedKey, isFirstInteractionPlayedUpd)
                videoElement.loop = true
                videoElement.src = avatarConfig.exhuman_idle_url
                videoElement.load()
            })
        }
    }, [isOnlyFirstIntroMessage, avatarConfig, isFirstInteractionPlayed])

    return <></>
}

const AudioVideoToggleComponent = ({ videoRef }: {
    videoRef: RefObject<HTMLVideoElement>
}) => {
    const dispatch = useDispatch()

    const isMuted = useSelector(selectIsExhumanMuted)
    const setIsMuted = (muted: boolean) => {
        dispatch(setIsExhumanMuted(muted))
    }

    useEffect(() => {
        const videoElement = videoRef.current
        if (!videoElement) {
            return
        }
        if (videoElement.muted != isMuted) {
            videoElement.muted = isMuted
        }
    }, [isMuted])

    return (
        <div className='flex justify-between items-center h-[50px]'>
            <div className='exh_audio-video'>
                Audio and video
            </div>
            <div className='cursor-pointer' onClick={() => setIsMuted(!isMuted)}>
                <img src={`/images/toggle-${isMuted ? 'off' : 'on'}.svg`} />
            </div>
        </div>
    )
}

const micSvg = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1C11.2044 1 10.4413 1.31607 9.87868 1.87868C9.31607 2.44129 9 3.20435 9 4V12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12V4C15 3.20435 14.6839 2.44129 14.1213 1.87868C13.5587 1.31607 12.7956 1 12 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 10V12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19V23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 23H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

