import { useEffect, useRef, useState } from "react"
import ChatHistoryComponent, { ChatHistoryComponentInterface } from "./ChatHistoryComponent"
import InputComponent, { defaultInputHeight } from "./InputComponent"
import { sendQuestionToMetahuman, sendQuestion, sendQuestionToExhumanHLS } from "../../app/api"
import * as amplitude from '@amplitude/analytics-browser';
import { useCookies } from "react-cookie"
import ControlsComponent from "./ControlsComponent"
import { messageSentKey } from "../../app/const-keys"
import VKComponent, { VKHeight } from "./VKComponent"
import { startSpeechRecognition, stopSpeechRecognition } from "../../app/voice-api-waiting"
import ProfileWidget from "./ProfileWidget"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectLastMessageToSend, setLastMessageToSend, setAvatarAnswer, selectIsExhumanMuted } from "../../app/redux/defaultSlice"
import { isBrowser } from "react-device-detect"
import { setupWaitingSpeechRecognition } from "../../app/voice-api-waiting"
import SuggestedTopicsComponent from "./SuggestedTopicsComponent";
import { customAlert, scrollToBottomSmoothly } from "../../app/utils";
import { UserQuestion } from "../../app/types";

export const chatWidth = 768

const ChatComponent = ({ metahuman: metahuman }: {
    metahuman?: boolean
}) => {
    const [input, setInput] = useState('')
    const historyContainerRef = useRef<HTMLDivElement>(null)
    const historyComponentRef = useRef<ChatHistoryComponentInterface>(null)
    const [cookies, setCookie] = useCookies<string>([messageSentKey]);
    const dispatch = useDispatch()
    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    const [inputHeight, setInputHeight] = useState(defaultInputHeight)

    const [isVKOn, setIsVKOn] = useState(true)
    const [isThinking, setIsThinking] = useState(false)

    const [isSuggestedTopicsOpened, setIsSuggestedTopicsOpened] = useState(false)

    const checkHistoryBlock = (newInputHeight: number) => {
        let defaultPadding = 0
        defaultPadding -= newInputHeight

        historyContainerRef.current!.style.paddingBottom = `${defaultPadding}px`
        historyContainerRef.current!.style.height = `calc(100% - ${newInputHeight}px)`
    }

    const [sendMessageAbortController, setSendMessageAbortController] = useState<AbortController | null>(null)
    useEffect(() => {
        const abc = new AbortController()
        setSendMessageAbortController(abc)
        return () => abc.abort()
    }, [])

    const oneRenderPassed = useRef(false)
    const [isVoiceOn, setIsVoiceOn] = useState(false)
    const [isUserSpeaking, setIsUserSpeaking] = useState(false)
    const isVoiceOnRef = useRef(false)
    const isBotHoldingMic = useRef(false)
    const lastMessageToSend = useSelector(selectLastMessageToSend)
    const setIsVoiceOnProxy = (val: boolean) => {
        setIsVoiceOn(val)
        isVoiceOnRef.current = val
        if (!isBotHoldingMic.current) {
            if (val) {
                startSpeechRecognition()
            } else {
                stopSpeechRecognition()
            }
        }
    }

    // sending
    useEffect(() => {
        if (lastMessageToSend) {
            doSend(lastMessageToSend)
            dispatch(setLastMessageToSend(null))
        }
    }, [lastMessageToSend])

    const addNewMessageAndScrollDown = (text: string, isUser: boolean) => {
        historyComponentRef.current?.addNewMessage(text, isUser)
    }

    const isMuted = useSelector(selectIsExhumanMuted)

    const doSend = async (question: UserQuestion) => {
        if (avatarConfig == null) {
            customAlert('no avatar loaded')
            return
        }

        question = { ...question }

        question.text = question.text.trim()
        if (question.text == '') {
            return
        }

        addNewMessageAndScrollDown(question.text, true)

        setInput('')

        processMessageCounting(cookies, setCookie, {
            'avatar_name': avatarConfig.name,
            'avatar_id': avatarConfig.id,
        })

        const timeoutInt = setTimeout(() => {
            setIsThinking(true)
        }, 1500)

        try {
            isBotHoldingMic.current = true
            if (isVoiceOnRef.current) {
                stopSpeechRecognition()
            }
            const onFinishedSpeaking = () => {
                isBotHoldingMic.current = false
                if (isVoiceOnRef.current) {
                    startSpeechRecognition()
                }
            }
            let avatarAnswer
            const cancelSignal = sendMessageAbortController!.signal
            if (metahuman) {
                const answerText = await sendQuestionToMetahuman(question, avatarConfig.name, onFinishedSpeaking, cancelSignal)
                avatarAnswer = {
                    text: answerText
                }

            } else {
                if (isMuted) {
                    avatarAnswer = await sendQuestion(question, avatarConfig.name, cancelSignal)

                } else {
                    avatarAnswer = await sendQuestionToExhumanHLS(question, avatarConfig.name, cancelSignal)
                }
            }

            if (!avatarAnswer) {
                customAlert('nil avatar answer')
                return
            }

            dispatch(setAvatarAnswer(avatarAnswer!))

            addNewMessageAndScrollDown(avatarAnswer.text, false)

            setIsVKOn(true)

        } catch (err) {
            isBotHoldingMic.current = false
            customAlert(`${err}`)
        } finally {
            clearTimeout(timeoutInt)
            setIsThinking(false)
        }
    }

    useEffect(() => {
        checkHistoryBlock(inputHeight)
        scrollToBottomSmoothly(historyContainerRef.current!, { smooth: false })

        if (!oneRenderPassed.current && metahuman) {
            const onNotAvailable = () => {
                // subdue button?
            }
            const onRecognized = (text: string) => {
                dispatch(setLastMessageToSend({ text, inputType: 'web asr' }))
            }
            const onError = (err: string) => {
                setIsVoiceOn(false)
                isVoiceOnRef.current = false
                customAlert(`Voice recognition error: ${err}`)
            }
            const onSpeaking = (isSpeaking: boolean) => {
                setIsUserSpeaking(isSpeaking)
            }
            setupWaitingSpeechRecognition(onNotAvailable, onRecognized, onError, onSpeaking)

            oneRenderPassed.current = true
        }
    }, [inputHeight])

    let inputPlaceholder = ''
    if (avatarConfig?.display_name) {
        inputPlaceholder = `Message ${avatarConfig?.display_name}...`
    }

    return (
        <div className='h-full flex overflow-hidden'>
            <div
                style={{
                    height: '100%',
                    transition: 'margin-right 0.5s ease',
                    width: chatWidth,
                    margin: `0px calc(50% - ${chatWidth / 2}px)`,
                }}
                className='flex flex-col flex-grow top-[0px]'
            >

                <ChatHistoryComponent
                    avatarConfig={avatarConfig}
                    isThinking={isThinking}
                    historyContainerRef={historyContainerRef}
                    ref={historyComponentRef}
                    isVKOn={isVKOn}
                    setIsVKOn={setIsVKOn}
                />

                <div
                    style={{
                        position: 'relative',
                        height: 0,
                        width: '100%',
                        pointerEvents: isSuggestedTopicsOpened ? undefined : 'none',

                        transition: '0.2s ease-out, opacity 0.2s ease-out',
                        transform: `translateY(${isSuggestedTopicsOpened ? 0 : 58}px)`,
                        opacity: isSuggestedTopicsOpened ? 1 : 0
                    }}>
                    <div
                        style={{
                            marginBottom: 8,
                            position: 'absolute',
                            height: 560,
                            bottom: 0,
                            width: chatWidth
                        }}
                        className='flex flex-col h-full'>
                        <SuggestedTopicsComponent
                            onSelectQuestion={(question: string) => {
                                dispatch(setLastMessageToSend({
                                    text: question,
                                    inputType: 'text'
                                }))
                                setIsSuggestedTopicsOpened(false)
                            }}
                            onCloseSuggestedTopics={() => {
                                setIsSuggestedTopicsOpened(false)
                            }} />
                    </div>
                </div>

                <div className='flex-grow' style={{ zIndex: 1 }}>
                    <InputComponent
                        placeholder={inputPlaceholder}
                        value={input}
                        onHeightChange={(newInputHeight: number) => {
                            setInputHeight(newInputHeight)
                        }}
                        onEnter={() => dispatch(setLastMessageToSend({
                            text: input,
                            inputType: 'text'
                        }))}
                        onChange={(newVal: string) => setInput(newVal)}
                        onSuggestedTopics={() => {
                            setIsSuggestedTopicsOpened(!isSuggestedTopicsOpened)
                        }}
                    />
                </div>

                {(isBrowser && metahuman) && <ControlsComponent
                    isVoiceOn={isVoiceOn}
                    setIsVoiceOn={setIsVoiceOnProxy}
                    isUserSpeaking={isUserSpeaking}
                />}

                {metahuman && <ProfileWidget metahuman />}

            </div>
        </div>
    )
}

const processMessageCounting = (
    cookies: { [key: string]: any },
    setCookie: (name: string, value: any) => void,
    eventDict: { [key: string]: any },
) => {
    const messagesSentNowNumber = (cookies[messageSentKey] ?? 0) + 1
    if (messagesSentNowNumber == 1) {
        amplitude.track('First message sent', { ...eventDict })
    } else if (messagesSentNowNumber == 3) {
        amplitude.track('Became a regular user', { ...eventDict })
    }
    setCookie(messageSentKey, messagesSentNowNumber)

    amplitude.track('Message sent', { ...eventDict, count: messagesSentNowNumber })
}

export default ChatComponent